export interface DynamicConfig {
    apiUrl: string;
}

export const defaultConfig: DynamicConfig = {
    apiUrl: "http://localhost:3979",
};

class GlobalConfig {
    config: DynamicConfig = defaultConfig;
}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = "configs/config.json";
