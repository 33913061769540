import './index.css';
import 'react-notifications/lib/notifications.css';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import { globalConfig } from './configuration/config';
import reportWebVitals from './reportWebVitals';
import ConfigService from './services/configService';
import SplashScreen from './components/SplashScreen';
import { app } from '@microsoft/teams-js';

const App = lazy(() => import('./App'));

declare global {
  interface Window {
    WebChat: any;
  }
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
export {};

const Root = () => {

  const DelayedApp: React.FC = () => {
    const [showApp, setShowApp] = useState(false);
  
    useEffect(() => {
      const delayTimeout = setTimeout(() => {
        setShowApp(true);
      }, 2000);
  
      return () => {
        clearTimeout(delayTimeout);
      };
    }, []);
  
    return showApp ? <App /> : <SplashScreen/>;
  };

  return (
    <>
      <ReactNotifications />
      <Suspense fallback={<SplashScreen/>}>
        { <DelayedApp />}
      </Suspense>
    </>
  );
};

ConfigService.getConfigs()
  .then(async (response) => {
    globalConfig.config = {
      apiUrl: response.data.embeddedServices.API.url,
    };
    if (window.location.hostname === "localhost") {
      root.render(<Root />)
    }
    else {
      try {
        await app.initialize()
        var context = app.getContext()
        if (context === null || context === undefined) {
          root.render(<SplashScreen />)
        }
        else {
          root.render(<Root />)
        }
      }
      catch (e) {
        root.render(<SplashScreen />)
      }
    }
  })
  .catch(() => {
    root.render(<p style={{ color: "red", textAlign: "center" }}>Error while fetching configs.</p>);
  });

reportWebVitals();