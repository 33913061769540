import { globalConfigUrl } from '../configuration/config';
import http from './httpService';

export default class ConfigService {

    static async getConfigs() {
        return http.get(globalConfigUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        });
    }
}