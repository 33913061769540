import React from 'react';

const SplashScreen: React.FC = () => {
    return (
        <div className='splashScreen' style={{backgroundImage: 'url(/images/SplashScreen.svg)'}} >
        </div>
    );
};

export default SplashScreen;
