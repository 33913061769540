import axios from 'axios';
import qs from 'query-string';

const http = axios.create({
  baseURL: "/",
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

http.interceptors.request.use(
  async function (config) {
    // const token = globalConfig.config.token;
    // if (token) {
    //   config.headers.common["Authorization"] = `${token}`;
    // }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default http;
